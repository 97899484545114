import { ADD_PREFIX } from './common';

export const BONUSES = 'bonuses';
export const BONUS_PROGRAM = ':bonusProgramId';
export const TRANSACTIONS = 'transactions';

export const BONUS_ROUTES = {
  BONUSES: `/${BONUSES}`,
  BONUS_PROGRAM: `/${BONUSES}/${BONUS_PROGRAM}`,
  BONUS_PROGRAM_TRANSACTIONS: `/${BONUSES}/${BONUS_PROGRAM}/${TRANSACTIONS}`,
  ADD_BONUS_PROGRAM: `/${BONUSES}/${ADD_PREFIX}`,
};
