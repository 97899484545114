import { NavigationTab } from 'shared/model';

export const TABS: Array<NavigationTab> = [
  {
    key: 'persons-group-list',
    path: 'persons-list',
    label: 'Список клиентов',
    controller: 'Group', //
    right: 'Write',
  },

  {
    key: 'session-log',
    path: 'session-log',
    label: 'Журнал сессий',
    controller: 'PrivateMethods',
    right: 'Read', //
    hasLoader: true,
  },
  {
    key: 'reserves',
    path: 'reserves',
    label: 'Журнал брони',
    controller: 'PrivateMethods',
    right: 'Read', //
  },
  {
    key: 'persons-group',
    path: '',
    label: 'Редактирование',
  },
];
