import { PERSON_ROUTES } from 'shared/consts/routes/person';
import { MenuAddButton } from 'shared/ui/section-menu';

export function AddPersonsGroupMenuButtonLink() {
  return (
    <MenuAddButton
      label="Добавить группу"
      path={PERSON_ROUTES.ADD_PERSONS_GROUP}
      requiredRights={[
        {
          controller: 'Person',
          right: 'Write',
        },
      ]}
    />
  );
}
