import { Menu, Spin } from 'antd';
import { useParams } from 'react-router-dom';

import { CustomLink } from 'shared/ui';
import { getOpenKeys } from 'shared/ui/ui-menu/lib/get-open-keys-array';
import { PERSONS, PERSON_ROUTES } from 'shared/consts/routes/person';

import { GroupDto } from 'entities/group';

import { StyledBasicMenu } from '../../../../common-styles/basic-menu';

import { PersonsGroupMenuItem } from './persons-menu-item';
import { AddPersonMenuButtonLink } from '../menu-button-link';
import { AddPersonsGroupMenuButtonLink } from 'entities/persons-group/ui/menu-button-link';

const key = PERSONS;

const fullPath = PERSON_ROUTES.PERSONS_LIST;
const label = 'Физ. лица';

function buildMenuItems(group: GroupDto, groups: GroupDto[]) {
  const { id, name, childrenGroups } = group;

  if (childrenGroups.length === 0) {
    return <PersonsGroupMenuItem group={group} />;
  }

  return (
    <CustomLink to={`${id}`}>
      <Menu.SubMenu key={String(id)} title={name} eventKey={String(id)}>
        {group.childrenGroups.map((childrenGroup) =>
          buildMenuItems(childrenGroup, groups)
        )}
      </Menu.SubMenu>
    </CustomLink>
  );
}

type Props = {
  loading: boolean;
  items: GroupDto[];
};

export function PersonGroupsMenu({ items, loading }: Props) {
  const { groupId } = useParams() as {
    groupId: string;
  };

  return (
    <>
      <AddPersonMenuButtonLink />
      <AddPersonsGroupMenuButtonLink />
      <StyledBasicMenu
        openKeys={
          Number(groupId)
            ? ['root', ...getOpenKeys(items, Number(groupId))]
            : ['root']
        }
        mode="inline"
        inlineIndent={15}
      >
        <Spin spinning={loading}>
          <CustomLink
            to={fullPath}
            checkIsActive={(path) =>
              path.includes(`${key}?`) || path === fullPath
            }
          >
            <Menu.SubMenu key="root" title={label} eventKey="root">
              {items
                .filter((k) => !k.parentId)
                .map((el) => buildMenuItems(el, items))}
            </Menu.SubMenu>
          </CustomLink>
        </Spin>
      </StyledBasicMenu>
    </>
  );
}
