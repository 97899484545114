import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  getServerErrorText,
  hasNoLetters,
  openErrorNotification,
} from 'shared/lib';
import { CHARGE_POINT_ROUTES } from 'shared/consts/routes/charge-point';
import { PERSON_ROUTES, PROFILE } from 'shared/consts/routes/person';

import { useLazyGetPersonsQuery } from 'entities/person';
import { selectTokenName } from 'entities/authorization';
import { useGetUserQuery } from 'entities/user';

import { Header } from '../header';
import { TABLE_PAGE_SIZE } from '../../../../constants/variables';

import { useLazyGetChargePointsQuery } from '../../../../redux/services/charge-points';

export function ConnectedHeader() {
  const [term, setTerm] = useState('');
  const [searchByChargePointName, setSearchByChargePointName] = useState(false);

  const userName = useSelector(selectTokenName);

  const navigate = useNavigate();

  // TODO: Вынести отдельно плашку юзера
  const { data, isFetching, error } = useGetUserQuery(userName);

  const [getPersonsTrigger, { isLoading: isPersonsLoading }] =
    useLazyGetPersonsQuery();
  const [getChargePointsTrigger, { isLoading: isChargePointsLoading }] =
    useLazyGetChargePointsQuery();

  const onSearch = async () => {
    if (hasNoLetters(term) && searchByChargePointName) {
      const res = await getChargePointsTrigger({
        search: 'name',
        searchText: term,
      });

      const { data, error } = res;

      if (data && data.statusCode !== 0) {
        openErrorNotification(data.statusDescription);
        return;
      }

      if (error) {
        openErrorNotification(getServerErrorText(error));
        return;
      }

      if (data) {
        const { chargePoints, count } = data;

        if (count > 1) {
          return navigate(
            `${CHARGE_POINT_ROUTES.CHARGE_POINTS}?search=name&searchText=${term}`
          );
        }

        if (count === 1) {
          const { idGroup, name } = chargePoints[0];

          return navigate(
            `${CHARGE_POINT_ROUTES.CHARGE_POINTS}/${idGroup}/${name}`
          );
        }

        if (count === 0) {
          return navigate(
            `${CHARGE_POINT_ROUTES.CHARGE_POINTS_SESSION_LOG}?search=operation.cpAddress&searchText=${term}`
          );
        }
      }
    }

    if (hasNoLetters(term) && !searchByChargePointName) {
      const res = await getPersonsTrigger({
        phone: term.replaceAll(/[\s\(\)-]/g, ''),
        page: 0,
      });

      const { data, error } = res;

      if (data && data.statusCode !== 0) {
        openErrorNotification(data.statusDescription);
        return;
      }

      if (error) {
        openErrorNotification(getServerErrorText(error));
        return;
      }

      if (data) {
        const { count, result } = data;

        if (count > 1) {
          return navigate(
            `${PERSON_ROUTES.PERSONS_LIST}?search=login&searchText=${term}`
          );
        }

        if (count === 1) {
          const { idGroup, login } = result[0];

          return navigate(
            `${PERSON_ROUTES.PERSONS_LIST}/${
              idGroup !== 0 ? idGroup : null
            }/${login}/${PROFILE}`
          );
        }

        if (count === 0) {
          return navigate(
            `${PERSON_ROUTES.PERSONS_SESSION_LOG}?search=userBrowseName&searchText=${term}`
          );
        }
      }

      if (res.data) {
        return navigate(`${PERSON_ROUTES.PERSONS_LIST}?phone=${term}`);
      }
    }

    return navigate(
      `${CHARGE_POINT_ROUTES.CHARGE_POINTS_SESSION_LOG}?search=operation.cpAddress&searchText=${term}`
    );
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setSearchByChargePointName(e.target.checked);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Отлов события очищения инпут-поля по крестику
    if (e.type !== 'change') {
      setTerm(e.target.value);
    }

    setTerm(e.target.value);
  };

  const firstName = data?.user.firstName ?? '';
  const lastName = data?.user.lastName ?? '';

  return (
    <Header
      onCheckboxChange={onCheckboxChange}
      onInputChange={onInputChange}
      onSearch={onSearch}
      searchByChargePointName={searchByChargePointName}
      term={term}
      loading={isPersonsLoading || isChargePointsLoading}
      firstName={firstName}
      lastName={lastName}
    />
  );
}
