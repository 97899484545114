import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';

import { ServerTableLayout } from 'shared/ui';

import { PersonDto, PersonsBaseResponse } from 'entities/person';

import { useColumns } from '../hooks/use-columns';
import {
  useFiltersState,
  usePaginationState,
  useSortingState,
} from 'shared/ui/table/hooks';
import { PERSON_ROUTES, PROFILE } from 'shared/consts/routes/person';

type Props = {
  tableData: PersonsBaseResponse['result'];
  loading: boolean;
  count: number | undefined;
  tablePageSize: number;
  tableHeadRef: (element: HTMLElement | SVGElement | null) => void;
  groupId?: string;
};

export function PersonsListTableInstance({
  tableData,
  loading,
  count,
  tableHeadRef,
  tablePageSize,
  groupId,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const columns = useColumns();

  const navigate = useNavigate();

  const ordering = searchParams.get('ordering');
  const type = searchParams.get('type');
  const phone = searchParams.get('phone');
  const page = searchParams.get('page');
  const limit = searchParams.get('limit');
  const search = searchParams.get('search');
  const searchText = searchParams.get('searchText');

  const data = useMemo(() => tableData, [tableData]);

  const sorting = useSortingState();
  const pagination = usePaginationState();
  const filters = useFiltersState();

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters: filters,
      pagination,
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    pageCount: count
      ? Math.ceil(count / (limit ? Number(limit) : tablePageSize))
      : undefined,
  });

  const handleRowDoubleClick = (row: Row<PersonDto>) => {
    const {
      original: { idGroup, login },
    } = row;

    navigate(
      `${PERSON_ROUTES.PERSONS_LIST}/${
        idGroup ? idGroup : null
      }/${login}/${PROFILE}`
    );
  };

  return (
    <ServerTableLayout
      table={table}
      loading={loading}
      pageSize={tablePageSize}
      tableHeadRef={tableHeadRef}
      handleRowDoubleClick={handleRowDoubleClick}
      rowsCount={count}
    />
  );
}
