import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ErrorMessage, EmptyData } from 'shared/ui';
import {
  openErrorNotification,
  openSuccessNotification,
  getServerErrorText,
  isErrorWithMessage,
  FORMAT_TO_DAYS,
  FORMAT_TO_SECONDS,
  showTimeString,
  toCurrencyUnit,
} from 'shared/lib';
import { PERSON_ROUTES, PROFILE } from 'shared/consts/routes/person';

import { selectTokenClaims, selectTokenRoles } from 'entities/authorization';
import {
  ConnectedPersonForm,
  PersonDto,
  PersonFormData,
  useGetPersonQuery,
} from 'entities/person';
import { ConnectedBonusSummary } from 'entities/bonus-program';

import { useCustomUpdatePersonMutation } from '../../../../redux/services/persons';

import { getValidatedFormData } from '../../helpers';
import { getEditError, getEditSuccess } from '../../constants';

import { Title, UserLogin } from './styles';

import { SUPERUSER_ROLE } from '../../../Security/constants';

import { CarsListWidget } from 'widgets/car/cars-list';

const getInitialValues = (person: PersonDto): PersonFormData => {
  const {
    id,
    registrationDate,
    login,
    email,
    chargingClientState,

    clientsDebt,
    commentary,
    userAgreementFlag,
    isBankCardAttached,
    chargingLimitBalance,
    name,
    isPushAvailable,
    idGroup,
    country,
    chargingClientType,
    company,
    isDebug,
    firstName,
    lastName,
    patronymic,
    sex,
    dateOfBirth,
    spendBonuses,
  } = person;

  return {
    id: String(id),
    registrationDate: registrationDate
      ? showTimeString(registrationDate, FORMAT_TO_SECONDS)
      : '',
    login,
    email,
    chargingClientState: String(chargingClientState),

    clientsDebt: toCurrencyUnit(clientsDebt),
    chargingLimitBalance: toCurrencyUnit(chargingLimitBalance),
    name,
    commentary,
    userAgreementFlag,
    isBankCardAttached,
    idGroup: String(idGroup),
    countryId: country === null ? '' : String(country.id),
    companyId: company === null ? '' : String(company.id),
    isPushAvailable,
    chargingClientType: String(chargingClientType),
    isDebug,
    firstName,
    lastName,
    patronymic,
    dateOfBirth: dateOfBirth ? showTimeString(dateOfBirth, FORMAT_TO_DAYS) : '',
    sex: String(sex),
    spendBonuses,
  };
};

export function EditPerson() {
  const roles = useSelector(selectTokenRoles);
  const claims = useSelector(selectTokenClaims);

  const [trigger, { isLoading: isMutationLoading }] =
    useCustomUpdatePersonMutation();

  const navigate = useNavigate();

  const { userLogin, groupId } = useParams() as {
    groupId: string;
    userLogin: string;
  };

  const { isLoading, error, data } = useGetPersonQuery(userLogin);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (data && data.statusCode !== 0) {
    return <ErrorMessage text={data.statusDescription} />;
  }

  if (error) {
    return <ErrorMessage text={getServerErrorText(error)} />;
  }

  if (!data) {
    return <EmptyData />;
  }

  const isAdmin = Boolean(roles.includes(SUPERUSER_ROLE));

  const sessionToken = isAdmin ? data.person.sessionToken : '';

  const initialValues = getInitialValues(data.person);

  const { carList } = data.person;

  const handleSubmit = async (values: PersonFormData) => {
    const formData = getValidatedFormData(values);

    const req = {
      ...formData,
      id: String(data.person.id),
    };

    try {
      const res = await trigger(req).unwrap();

      // костыль
      if (res.statusCode !== 0) {
        openErrorNotification(res.statusDescription);
        return;
      }
      //

      const successText = getEditSuccess(userLogin);

      openSuccessNotification(successText);

      navigate(
        `${PERSON_ROUTES.PERSONS_LIST}/${groupId}/${userLogin}/${PROFILE}`
      );
    } catch (err) {
      const hasErrorMessage = isErrorWithMessage(err);

      const errorText = hasErrorMessage
        ? err.data.statusDescription
        : getEditError(userLogin);

      openErrorNotification(errorText);
    }
  };

  const personLogin = data.person.login;

  return (
    <ConnectedPersonForm
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      loading={isMutationLoading}
      disabled
      title={
        <Title>
          Пользователь <UserLogin>{` ${personLogin}`}</UserLogin>
        </Title>
      }
      sessionToken={sessionToken}
      carListElement={
        <CarsListWidget cars={carList} personLogin={personLogin} />
      }
      bonusInfo={<ConnectedBonusSummary personId={data.person.clientNodeId} />}
    />
  );
}
