import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getGroupedRowModel,
  GroupingState,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Excel from 'exceljs';

import { ExportButton } from 'shared/ui';
import {
  getRuFormatDateString,
  replaceSubstring,
  toFixedDecimals,
} from 'shared/lib';

import {
  ReportInfo,
  ReportTableLayout,
  StyledReportCard,
  ReportHeader,
  applyWorksheetStyles,
  downloadReport,
  setColumnsAutoWidth,
  setReportMetadata,
  DEFAULT_REPORT_COLUMN_WIDTH,
  type ChargePointPaymentDto,
  getSelectedGroupsNames,
  highlightLastRow,
} from 'entities/report';
import { GroupDto } from 'entities/group';

const PAYMENTS_TOTAL_GROUP = 'Сумма потребления (руб.)';
const CONSUME_TOTAL_GROUP = 'Потреблено (кВт*ч)';

const COLUMN = {
  operationDate: { id: 'operationDate', header: 'Дата' },
  cpName: { id: 'cpName', header: 'Номер ЭЗС' },
  usersPayment: { id: 'payments', header: 'Физ. лица' },
  usersBonus: { id: 'bonus', header: 'Физ. лица бонус' },
  contractorsPayment: { id: 'contractorPayments', header: 'Юр. лица' },
  usersDebit: { id: 'debit', header: 'Задолженность (физ. лица)' },
  usersConsume: { id: 'consume', header: 'Физ. лица' },
  contractorsConsume: { id: 'consumeContractor', header: 'Юр. лица' },
  paymentsTotal: { id: 'paymentsTotal', header: 'Итого' },
  consumeTotal: { id: 'consumeTotal', header: 'Итого' },
};

const COLUMNS_ROW = 8;
const REPORT_TITLE = 'Отчет по выручке';

const columnHelper = createColumnHelper<ChargePointPaymentDto>();

const emptyArr: ChargePointPaymentDto[] = [];

const getReportTitle = (groupBy: string): string => {
  const commonPart = 'Сводный отчет по выручке';

  if (groupBy === '1') {
    return `${commonPart} (дни)`;
  }

  if (groupBy === '2') {
    return `${commonPart} (месяцы)`;
  }

  return `${commonPart} за период`;
};

type Props = {
  payments: ChargePointPaymentDto[];
  loading: boolean;
  groups: GroupDto[];
};

export function PaymentsReportTable({ groups, loading, payments }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const dateFrom = searchParams.get('dateFrom');
  const dateTo = searchParams.get('dateTo');
  const chargePointsParam = searchParams.get('chargePoints');
  const groupsParam = searchParams.get('groups');
  const groupByParam = searchParams.get('groupBy') ?? '1';

  const [grouping, setGrouping] = useState<GroupingState>([]);

  const usersPaymentSummary = toFixedDecimals(
    payments.reduce((acc, el) => acc + el.payments, 0),
    2
  );

  const usersBonusSummary = toFixedDecimals(
    payments.reduce((acc, el) => acc + el.bonus, 0),
    2
  );

  const contractorsPaymentSummary = toFixedDecimals(
    payments.reduce((acc, el) => acc + el.contractorPayments, 0),
    2
  );

  const paymentsTotal = toFixedDecimals(
    payments.reduce(
      (acc, { payments, bonus, contractorPayments }) =>
        acc + payments + bonus + contractorPayments,
      0
    ),
    2
  );

  const usersDebitSummary = toFixedDecimals(
    payments.reduce((acc, el) => acc + el.debit, 0),
    2
  );

  const usersConsumeSummary = toFixedDecimals(
    payments.reduce((acc, el) => (acc * 1000 + el.consume * 1000) / 1000, 0),
    3
  );

  const contractorsConsumeSummary = toFixedDecimals(
    payments.reduce(
      (acc, el) => (acc * 1000 + el.consumeContractor * 1000) / 1000,
      0
    ),
    3
  );

  const consumeTotal = toFixedDecimals(
    payments.reduce(
      (acc, { consume, consumeContractor }) =>
        (acc * 1000 + consume * 1000 + consumeContractor * 1000) / 1000,
      0
    ),
    3
  );

  const TABLE_COLUMN = {
    operationDate: columnHelper.accessor('operationDate', {
      ...COLUMN.operationDate,
      footer: '',
      cell: (props) => props.getValue().replaceAll('-', '.'),
      size: 155,
    }),
    cpName: columnHelper.accessor('cpName', {
      ...COLUMN.cpName,
      footer: 'Итого:',
      size: 155,
    }),
    usersPayment: columnHelper.accessor('payments', {
      ...COLUMN.usersPayment,
      cell: (props) => toFixedDecimals(props.getValue(), 2),
      footer: String(usersPaymentSummary),
    }),
    contractorsPayment: columnHelper.accessor('contractorPayments', {
      ...COLUMN.contractorsPayment,
      cell: (props) => toFixedDecimals(props.getValue(), 2),
      footer: String(contractorsPaymentSummary),
    }),
    usersDebit: columnHelper.accessor('debit', {
      ...COLUMN.usersDebit,
      cell: (props) => toFixedDecimals(props.getValue(), 2),
      footer: String(usersDebitSummary),
    }),
    usersBonus: columnHelper.accessor('bonus', {
      ...COLUMN.usersBonus,
      cell: (props) => toFixedDecimals(props.getValue(), 2),
      footer: String(usersBonusSummary),
    }),
    usersConsume: columnHelper.accessor('consume', {
      ...COLUMN.usersConsume,
      cell: (props) => toFixedDecimals(props.getValue(), 3),
      footer: String(usersConsumeSummary),
    }),
    contractorsConsume: columnHelper.accessor('consumeContractor', {
      ...COLUMN.contractorsConsume,
      cell: (props) => toFixedDecimals(props.getValue(), 3),
      footer: String(contractorsConsumeSummary),
    }),
  };

  const setReportHeader = (ws: Excel.Worksheet, lastColumn: string) => {
    ws.mergeCells('A1', `${lastColumn}1`);

    ws.getCell('A2').value = 'Начало периода:';
    ws.mergeCells('B2', `${lastColumn}2`);
    ws.getCell('B2').value = getRuFormatDateString(dateFrom as string);

    ws.getCell('A3').value = 'Окончание периода:';
    ws.mergeCells('B3', `${lastColumn}3`);
    ws.getCell('B3').value = getRuFormatDateString(dateTo as string);

    ws.getCell('A4').value = 'Дата отчета:';
    ws.mergeCells('B4', `${lastColumn}4`);
    ws.getCell('B4').value = getRuFormatDateString();

    ws.getCell('A5').value = 'Список ЭЗС:';
    ws.mergeCells('B5', `${lastColumn}5`);
    ws.getCell('B5').value = chargePointsParam;

    ws.getCell('A6').value = 'Группы ЭЗС:';
    ws.mergeCells('B6', `${lastColumn}6`);
    ws.getCell('B6').value =
      groupsParam !== null ? getSelectedGroupsNames(groupsParam, groups) : '';
  };

  const buildTableByDays = (ws: Excel.Worksheet) => {
    setReportHeader(ws, 'J');

    ws.getRow(COLUMNS_ROW).values = [
      COLUMN.operationDate.header,
      COLUMN.cpName.header,
      PAYMENTS_TOTAL_GROUP,
      '',
      '',
      '',
      COLUMN.usersDebit.header,
      CONSUME_TOTAL_GROUP,
      '',
      '',
    ];

    ws.getRow(COLUMNS_ROW + 1).values = [
      '',
      '',
      COLUMN.usersPayment.header,
      COLUMN.usersBonus.header,
      COLUMN.contractorsPayment.header,
      COLUMN.paymentsTotal.header,
      '',
      COLUMN.usersConsume.header,
      COLUMN.contractorsConsume.header,
      COLUMN.consumeTotal.header,
    ];

    ws.mergeCells('A8', 'A9');
    ws.mergeCells('B8', 'B9');
    ws.mergeCells('C8', 'F8');
    ws.mergeCells('G8', 'G9');
    ws.mergeCells('H8', 'J8');

    ws.columns = [
      { key: COLUMN.operationDate.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.cpName.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.usersPayment.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.usersBonus.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.contractorsPayment.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.paymentsTotal.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.usersDebit.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.usersConsume.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.contractorsConsume.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.consumeTotal.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
    ];

    payments.forEach(
      ({
        operationDate,
        cpName,
        payments,
        bonus,
        contractorPayments,
        debit,
        consume,
        consumeContractor,
      }) => {
        ws.addRow({
          operationDate: replaceSubstring(operationDate, '-', '.'),
          cpName,
          payments: replaceSubstring(toFixedDecimals(payments, 2), '.', ','),
          bonus: replaceSubstring(toFixedDecimals(bonus, 2), '.', ','),
          contractorPayments: replaceSubstring(
            toFixedDecimals(contractorPayments, 2),
            '.',
            ','
          ),
          paymentsTotal: replaceSubstring(
            toFixedDecimals(payments + bonus + contractorPayments, 2),
            '.',
            ','
          ),
          debit: replaceSubstring(toFixedDecimals(debit, 2), '.', ','),
          consume: replaceSubstring(toFixedDecimals(consume, 3), '.', ','),
          consumeContractor: replaceSubstring(
            toFixedDecimals(consumeContractor, 3),
            '.',
            ','
          ),
          consumeTotal: replaceSubstring(
            toFixedDecimals(
              (consume * 1000 + consumeContractor * 1000) / 1000,
              3
            ),
            '.',
            ','
          ),
        });
      }
    );

    ws.addRow({
      operationDate: 'Итого:',
      cpName: '',
      payments: replaceSubstring(usersPaymentSummary, '.', ','),
      bonus: replaceSubstring(usersBonusSummary, '.', ','),
      contractorPayments: replaceSubstring(contractorsPaymentSummary, '.', ','),
      paymentsTotal: replaceSubstring(paymentsTotal, '.', ','),
      debit: replaceSubstring(usersDebitSummary, '.', ','),
      consume: replaceSubstring(usersConsumeSummary, '.', ','),
      consumeContractor: replaceSubstring(contractorsConsumeSummary, '.', ','),
      consumeTotal: replaceSubstring(consumeTotal, '.', ','),
    });

    applyWorksheetStyles(ws, COLUMNS_ROW);
    highlightLastRow(ws);

    ws.getRow(COLUMNS_ROW + 1).font = {
      bold: true,
      size: 13,
    };
    ws.getRow(COLUMNS_ROW + 1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '99ccff' },
      };
    });
    ws.getRow(COLUMNS_ROW + 1).height = 40;

    const lastRowIndex = payments.length + COLUMNS_ROW + 2;

    ws.mergeCells(`A${lastRowIndex}:B${lastRowIndex}`);

    ws.getCell('A1').value = getReportTitle('1');
    ws.getCell('B2').alignment = {
      horizontal: 'left',
    };
    ws.getCell('B3').alignment = {
      horizontal: 'left',
    };
    ws.getCell('B4').alignment = {
      horizontal: 'left',
    };
    ws.getCell('B5').alignment = {
      horizontal: 'left',
    };
    ws.getCell('B6').alignment = {
      horizontal: 'left',
    };

    setColumnsAutoWidth(ws, COLUMNS_ROW + 1);
  };

  const buildTableByPeriod = (ws: Excel.Worksheet) => {
    setReportHeader(ws, 'I');

    ws.getRow(COLUMNS_ROW).values = [
      COLUMN.cpName.header,
      PAYMENTS_TOTAL_GROUP,
      '',
      '',
      '',
      COLUMN.usersDebit.header,
      CONSUME_TOTAL_GROUP,
      '',
      '',
    ];

    ws.getRow(COLUMNS_ROW + 1).values = [
      '',
      COLUMN.usersPayment.header,
      COLUMN.usersBonus.header,
      COLUMN.contractorsPayment.header,
      COLUMN.paymentsTotal.header,
      '',
      COLUMN.usersConsume.header,
      COLUMN.contractorsConsume.header,
      COLUMN.consumeTotal.header,
    ];

    ws.mergeCells('A8', 'A9');
    ws.mergeCells('B8', 'E8');
    ws.mergeCells('F8', 'F9');
    ws.mergeCells('G8', 'I8');

    ws.columns = [
      { key: COLUMN.cpName.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.usersPayment.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.usersBonus.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.contractorsPayment.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.paymentsTotal.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.usersDebit.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.usersConsume.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.contractorsConsume.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
      { key: COLUMN.consumeTotal.id, width: DEFAULT_REPORT_COLUMN_WIDTH },
    ];

    applyWorksheetStyles(ws, COLUMNS_ROW);

    ws.getRow(COLUMNS_ROW + 1).font = {
      bold: true,
      size: 13,
    };
    ws.getRow(COLUMNS_ROW + 1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '99ccff' },
      };
    });
    ws.getRow(COLUMNS_ROW + 1).height = 40;

    payments.forEach(
      ({
        cpName,
        payments,
        bonus,
        contractorPayments,
        debit,
        consume,
        consumeContractor,
      }) => {
        ws.addRow({
          cpName,
          payments: replaceSubstring(toFixedDecimals(payments, 2), '.', ','),
          bonus: replaceSubstring(toFixedDecimals(bonus, 2), '.', ','),
          contractorPayments: replaceSubstring(
            toFixedDecimals(contractorPayments, 2),
            '.',
            ','
          ),
          paymentsTotal: replaceSubstring(
            toFixedDecimals(payments + bonus + contractorPayments, 2),
            '.',
            ','
          ),
          debit: replaceSubstring(toFixedDecimals(debit, 2), '.', ','),
          consume: replaceSubstring(toFixedDecimals(consume, 3), '.', ','),
          consumeContractor: replaceSubstring(
            toFixedDecimals(consumeContractor, 3),
            '.',
            ','
          ),
          consumeTotal: replaceSubstring(
            toFixedDecimals(
              (consume * 1000 + consumeContractor * 1000) / 1000,
              3
            ),
            '.',
            ','
          ),
        });
      }
    );

    ws.addRow({
      cpName: 'Итого:',
      payments: replaceSubstring(usersPaymentSummary, '.', ','),
      bonus: replaceSubstring(usersBonusSummary, '.', ','),
      contractorPayments: replaceSubstring(contractorsPaymentSummary, '.', ','),
      paymentsTotal: replaceSubstring(paymentsTotal, '.', ','),
      debit: replaceSubstring(usersDebitSummary, '.', ','),
      consume: replaceSubstring(usersConsumeSummary, '.', ','),
      consumeContractor: replaceSubstring(contractorsConsumeSummary, '.', ','),
      consumeTotal: replaceSubstring('consumeTotal', '.', ','),
    });

    ws.getCell('A1').value = getReportTitle('1');
    ws.getCell('B2').alignment = {
      horizontal: 'left',
    };
    ws.getCell('B3').alignment = {
      horizontal: 'left',
    };
    ws.getCell('B4').alignment = {
      horizontal: 'left',
    };
    ws.getCell('B5').alignment = {
      horizontal: 'left',
    };
    ws.getCell('B6').alignment = {
      horizontal: 'left',
    };

    setColumnsAutoWidth(ws, COLUMNS_ROW + 1);
  };

  const generateTable = async () => {
    const workbook = new Excel.Workbook();

    setReportMetadata(workbook);

    const worksheet = workbook.addWorksheet(REPORT_TITLE);

    if (groupByParam === '1') {
      buildTableByDays(worksheet);
    } else if (groupByParam === '2') {
      buildTableByDays(worksheet);
    } else if (groupByParam === '3') {
      buildTableByPeriod(worksheet);
    }

    const reportFileName = `${getReportTitle(groupByParam).replace(
      ' ',
      '_'
    )}${dateFrom}-${dateTo}`;

    downloadReport(workbook, reportFileName);
  };

  const groupByDayColumns = [
    columnHelper.group({
      id: 'dateAndCpNameGroup',
      header: '',
      columns: [TABLE_COLUMN.operationDate, TABLE_COLUMN.cpName],
    }),
    columnHelper.group({
      id: 'paymentsGroup',
      header: 'Итого (выручка)',
      footer: '',
      columns: [
        TABLE_COLUMN.usersPayment,
        TABLE_COLUMN.usersBonus,
        TABLE_COLUMN.contractorsPayment,
        columnHelper.display({
          header: COLUMN.paymentsTotal.header,
          footer: String(paymentsTotal),
          id: 'paymentsTotal',
          cell: (props) => {
            const { payments, bonus, contractorPayments } = props.row.original;

            return toFixedDecimals(payments + bonus + contractorPayments, 2);
          },
        }),
      ],
    }),
    columnHelper.group({
      id: 'debitGroup',
      header: '',
      footer: '',
      columns: [TABLE_COLUMN.usersDebit],
    }),
    columnHelper.group({
      id: 'consumeGroup',
      header: 'Итого (потребление)',
      footer: '',
      columns: [
        TABLE_COLUMN.usersConsume,
        TABLE_COLUMN.contractorsConsume,
        columnHelper.display({
          header: COLUMN.consumeTotal.header,
          footer: String(consumeTotal),
          id: 'consumeTotal',
          cell: (props) => {
            const { consume, consumeContractor } = props.row.original;

            return toFixedDecimals(
              (consume * 1000 + consumeContractor * 1000) / 1000,
              3
            );
          },
        }),
      ],
    }),
  ];

  const groupByPeriodColumns = [
    columnHelper.group({
      id: 'cpNameGroup',
      header: '',
      columns: [TABLE_COLUMN.cpName],
    }),
    columnHelper.group({
      id: 'paymentsGroup',
      header: PAYMENTS_TOTAL_GROUP,
      footer: '',
      columns: [
        TABLE_COLUMN.usersPayment,
        TABLE_COLUMN.usersBonus,
        TABLE_COLUMN.contractorsPayment,
        columnHelper.display({
          header: COLUMN.paymentsTotal.header,
          footer: String(paymentsTotal),
          id: 'paymentsTotal',
          cell: (props) => {
            const { payments, bonus, contractorPayments } = props.row.original;

            return toFixedDecimals(payments + bonus + contractorPayments, 2);
          },
        }),
      ],
    }),
    columnHelper.group({
      id: 'debitGroup',
      header: '',
      footer: '',
      columns: [TABLE_COLUMN.usersDebit],
    }),
    columnHelper.group({
      id: 'consumeGroup',
      header: CONSUME_TOTAL_GROUP,
      footer: '',
      columns: [
        TABLE_COLUMN.usersConsume,
        TABLE_COLUMN.contractorsConsume,
        columnHelper.display({
          header: COLUMN.consumeTotal.header,
          footer: String(consumeTotal),
          id: 'consumeTotal',
          cell: (props) => {
            const { consume, consumeContractor } = props.row.original;

            return toFixedDecimals(
              (consume * 1000 + consumeContractor * 1000) / 1000,
              3
            );
          },
        }),
      ],
    }),
  ];

  const defaultColumnsMapping: Record<
    string,
    ColumnDef<ChargePointPaymentDto, unknown>[]
  > = {
    1: groupByDayColumns,
    2: groupByDayColumns,
    3: groupByPeriodColumns,
  };

  const columns = useMemo(() => {
    return defaultColumnsMapping[groupByParam];
  }, [
    usersPaymentSummary,
    usersBonusSummary,
    contractorsPaymentSummary,
    usersDebitSummary,
    usersConsumeSummary,
    contractorsConsumeSummary,
    groupByParam,
  ]);

  const prepareData = (payments: ChargePointPaymentDto[]) =>
    payments.map((el) => {
      const {
        payments,
        bonus,
        contractorPayments,
        consume,
        consumeContractor,
      } = el;

      return {
        ...el,
        paymentsTotal: toFixedDecimals(
          payments + bonus + contractorPayments,
          2
        ),
        consumeTotal: toFixedDecimals(
          (consume * 1000 + consumeContractor * 1000) / 1000,
          3
        ),
      };
    });

  const preparedData = useMemo(() => {
    return payments ? prepareData(payments) : emptyArr;
  }, [payments]);

  const table = useReactTable({
    data: preparedData,
    columns,
    state: {
      grouping,
    },
    onGroupingChange: setGrouping,
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <StyledReportCard>
      <ReportHeader>
        <ReportInfo
          title={REPORT_TITLE}
          dateFromParam={dateFrom}
          dateToParam={dateTo}
        />
        <ExportButton
          onClick={generateTable}
          disabled={loading || !payments.length}
        />
      </ReportHeader>
      <ReportTableLayout
        table={table}
        loading={loading}
        ignoreHeaderGroups={[]}
        includeFooterGroups={['1']}
      />
    </StyledReportCard>
  );
}
