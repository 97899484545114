import dayjs from 'dayjs';

import { toCurrencySubunit } from 'shared/lib';

import {
  ChargingClientState,
  ChargingClientType,
  PersonFormData,
  Sex,
} from '../model';

export const prepareRequestData = (values: PersonFormData) => {
  const {
    registrationDate,
    clientsDebt,
    chargingLimitBalance,
    name,
    chargingClientType,
    chargingClientState,
    idGroup,
    countryId,
    companyId,
    dateOfBirth,
    sex,
    ...rest
  } = values;

  return {
    ...rest,
    dateOfBirth: dateOfBirth
      ? dayjs(dateOfBirth, 'DD-MM-YYYY').toISOString()
      : '',
    registrationDate: registrationDate
      ? dayjs(registrationDate, 'DD-MM-YYYY HH:mm:ss').toISOString()
      : '',

    clientsDebt: toCurrencySubunit(clientsDebt),
    chargingLimitBalance:
      chargingClientType === '1' ? toCurrencySubunit(chargingLimitBalance) : 0,
    name: chargingClientType === '1' ? name : '',
    chargingClientType: Number(chargingClientType) as ChargingClientType,
    chargingClientState: Number(chargingClientState) as ChargingClientState,
    idGroup: Number(idGroup),
    countryId: Number(countryId),
    companyId: Number(companyId),
    sex: Number(sex) as Sex,
  };
};
